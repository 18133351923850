import './App.css';
import { useState, useEffect } from 'react';
import logoIwie from './assets/logo-iwiespace.webp';
import logoSinnotaria from './assets/logoreal3.svg';

function App() {
  const [currentFeature, setCurrentFeature] = useState(0);
  const [menuOpen, setMenuOpen] = useState(false);
  
  const features = [
    {
      subtitle: "Ahorra Tiempo",
      text: "Genera documentos en minutos."
    },
    {
      subtitle: "Reduce Errores",
      text: "Evita errores humanos."
    },
    {
      subtitle: "Personaliza Contratos",
      text: "Adapta los documentos a tus necesidades."
    },
    {
      subtitle: "Optimiza Procesos",
      text: "Simplifica la gestión de documentos."
    }
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentFeature((prev) => (prev + 1) % features.length);
    }, 3000);
    return () => clearInterval(timer);
  }, [features.length]);

  const scrollToInfo = () => {
    const infoSection = document.querySelector('.info-section');
    const yOffset = infoSection.getBoundingClientRect().top + window.pageYOffset;
    
    window.scrollTo({
      top: yOffset,
      behavior: 'smooth'
    });
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="App">
      <nav className="main-nav">
        <div className="nav-container">
          <div className="nav-logo">
            <img src={logoSinnotaria} alt="SinnotarIA" className="nav-logo-img" />
          </div>
          <div className={`menu-toggle ${menuOpen ? 'active' : ''}`} onClick={toggleMenu}>
            <span></span>
            <span></span>
            <span></span>
          </div>
          <ul className={`nav-links ${menuOpen ? 'active' : ''}`}>
            <li><a href="#info" onClick={() => setMenuOpen(false)}>¿Qué es?</a></li>
            <li><a href="#solutions" onClick={() => setMenuOpen(false)}>Soluciones</a></li>
            <li><a href="#contact" onClick={() => setMenuOpen(false)}>Contacto</a></li>
            <li><a href="https://app.sinnotaria.cl/" target="_blank" rel="noopener noreferrer" className="nav-button" onClick={() => setMenuOpen(false)}>Prueba Gratuita</a></li>
          </ul>
        </div>
      </nav>
      
      <header className="App-header">
        <h1 className="title">
          <img src={logoSinnotaria} alt="SinnotarIA" className="frontal" />
        </h1>
        <h2 className="subtitle">
          Gestión de Documentos con Inteligencia Artificial
        </h2>
        <p className="description">
          SinnotarIA: Gestión de Documentos automatizada con IA. Redacción, firmas, validación y digitalización de documentos.
        </p>
        <div className="button-container">
          <a href="https://app.sinnotaria.cl/" target="_blank" rel="noopener noreferrer" className="primary-button">
            Prueba Gratuita
          </a>
        </div> 
        <button className="scroll-button" onClick={scrollToInfo}>
          <i className="fas fa-chevron-down"></i>
        </button>
      </header>

      <section className="info-section" id="info">
        <h2 className="section-title">¿Qué es SinnotarIA?</h2>
        <div className="info-grid">
          <div className="info-card">
            <h3 className="info-subtitle">Gestión de Documentos eficiente</h3>
            <p className="info-text">
              Sinnotaria es una plataforma innovadora que moderniza la Gestión de Documentos utilizando la Inteligencia Artificial. Ofrecemos una solución integral que simplifica los procesos documentales y te ayuda a tomar decisiones más rápidas e informadas.
            </p>
          </div>
          <div className="info-card">
            <h3 className="info-subtitle">Cumplimiento normativo</h3>
            <p className="info-text">
              Nuestra plataforma te ayuda a mantenerte al día con las últimas leyes y regulaciones, asegurando que tus documentos y contratos cumplan con los estándares legales más estrictos.
            </p>
          </div>
        </div>
      </section>

      <section className="solutions-section" id="solutions">
        <div className="floating-paper">
          <div className="paper"></div>
          <div className="paper"></div>
          <div className="paper"></div>
          <div className="paper"></div>
          <div className="paper"></div>
          <div className="paper"></div>
          <div className="paper"></div>
          <div className="paper"></div>
        </div>
        <h2 className="section-title">Nuestras Soluciones</h2>
        <div className="solutions-grid">
          <div className="solution-card">
            <div className="solution-image">
              <img src="/NS1.webp" alt="Automatización de documentos" />
            </div>
            <h3 className="solution-subtitle">Automatización de documentos</h3>
            <p className="solution-text">
              Crea documentos legales de forma rápida y eficiente. Redacta contratos, cartas y otros documentos sin errores.
            </p>
          </div>

          <div className="solution-card">
            <div className="solution-image">
              <img src="/NS2.webp" alt="Asistencia con chatbots" />
            </div>
            <h3 className="solution-subtitle">Asistencia con chatbots</h3>
            <p className="solution-text">
              Chatbots inteligentes te brindan asistencia 24/7. Obtén respuestas rápidas a tus preguntas legales.
            </p>
          </div>

          <div className="solution-card">
            <div className="solution-image">
              <img src="/NS3.webp" alt="Validación de contratos" />
            </div>
            <h3 className="solution-subtitle">Validación de contratos</h3>
            <p className="solution-text">
              Analizamos tus contratos y te alertamos sobre posibles riesgos.
            </p>
          </div>

          <div className="solution-card">
            <div className="solution-image">
              <img src="/NS4.webp" alt="Digitalización de documentos" />
            </div>
            <h3 className="solution-subtitle">Digitalización de documentos</h3>
            <p className="solution-text">
              Digitaliza tus documentos legales de forma rápida y segura. Transforma archivos físicos en digitales.
            </p>
          </div>
        </div>
      </section>

      <section className="automation-section">
        <div className="automation-content">
          <div className="automation-text">
            <h2 className="section-title">Automatización de Documentos</h2>
            <div className="feature-container">
              <h3 className="feature-subtitle">{features[currentFeature].subtitle}</h3>
              <p className="feature-text">{features[currentFeature].text}</p>
            </div>
          </div>
          <div className="automation-image">
            <img src="/AD1.webp" alt="Automatización de documentos" />
          </div>
        </div>
      </section>

      <section className="chatbot-section">
        <div className="floating-paper">
          <div className="paper"></div>
          <div className="paper"></div>
          <div className="paper"></div>
          <div className="paper"></div>
          <div className="paper"></div>
          <div className="paper"></div>
          <div className="paper"></div>
          <div className="paper"></div>
        </div>
        <div className="chatbot-content">
          <div className="chatbot-image">
            <img src="/AC1.webp" alt="Asistencia con chatbot" />
          </div>
          <div className="chatbot-text">
            <h2 className="section-title">Asistencia con chatbot</h2>
            <div className="features-grid">
              <div className="feature-item">
                <div className="feature-icon">
                  <i className="fas fa-info-circle"></i>
                </div>
                <h3 className="feature-title">Respuestas Rápidas</h3>
                <p className="feature-description">Obtén información al instante sobre temas legales.</p>
              </div>
              
              <div className="feature-item">
                <div className="feature-icon">
                  <i className="fas fa-clock"></i>
                </div>
                <h3 className="feature-title">Disponible 24/7</h3>
                <p className="feature-description">Obtén asistencia en cualquier momento.</p>
              </div>
              
              <div className="feature-item">
                <div className="feature-icon">
                  <i className="fas fa-question-circle"></i>
                </div>
                <h3 className="feature-title">Preguntas frecuentes</h3>
                <p className="feature-description">Encuentra respuestas a tus preguntas comunes.</p>
              </div>
              
              <div className="feature-item">
                <div className="feature-icon">
                  <i className="fas fa-comments"></i>
                </div>
                <h3 className="feature-title">Conversación natural</h3>
                <p className="feature-description">Interactúa con nuestro chatbot de forma sencilla.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="validation-section">
        <div className="validation-content">
          <h2 className="section-title">Validación de contratos</h2>
          <div className="validation-features">
            <div className="validation-item">
              <h3 className="validation-subtitle">Identifica riesgos</h3>
              <p className="validation-text">Nuestras herramientas de IA detectan cláusulas potencialmente peligrosas.</p>
            </div>
            
            <div className="validation-item">
              <h3 className="validation-subtitle">Evalúa la legalidad</h3>
              <p className="validation-text">Verifica el cumplimiento normativo de tus contratos.</p>
            </div>
            
            <div className="validation-item">
              <h3 className="validation-subtitle">Protege tus intereses</h3>
              <p className="validation-text">Toma decisiones informadas y seguras.</p>
            </div>
          </div>
        </div>
      </section>

      <section className="digitalization-section">
        <div className="floating-paper">
          <div className="paper"></div>
          <div className="paper"></div>
          <div className="paper"></div>
          <div className="paper"></div>
          <div className="paper"></div>
          <div className="paper"></div>
          <div className="paper"></div>
          <div className="paper"></div>
        </div>
        <div className="digitalization-content">
          <h2 className="section-title">Digitalización de Documentos</h2>
          <div className="digitalization-features">
            <div className="digitalization-item">
              <h3 className="digitalization-subtitle">Escanea documentos</h3>
              <p className="digitalization-text">Carga tus archivos en formato digital.</p>
            </div>
            
            <div className="digitalization-item">
              <h3 className="digitalization-subtitle">OCR avanzado</h3>
              <p className="digitalization-text">Nuestra tecnología convierte texto en digital.</p>
            </div>

            <div className="digitalization-item">
              <h3 className="digitalization-subtitle">Documentación organizada</h3>
              <p className="digitalization-text">Gestiona y almacena tus documentos digitalmente.</p>
            </div>
          </div>
        </div>
      </section>

      <section className="biometric-section">
        <div className="matrix-background">
          {[...Array(30)].map((_, i) => (
            <div key={i} className="matrix-column">
              {[...Array(20)].map((_, j) => {
                const chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz@#$%^&*()_+=<>{}[]";
                const randomChar = chars[Math.floor(Math.random() * chars.length)];
                return (
                  <span key={j} className="matrix-symbol">
                    {randomChar}
                  </span>
                );
              })}
            </div>
          ))}
        </div>
        <div className="biometric-content">
          <h2 className="section-title">Verificación biométrica y asistente virtual</h2>
          <div className="biometric-features">
            <div className="biometric-item">
              <h3 className="biometric-subtitle">Seguridad</h3>
              <p className="biometric-text">Firmas electrónicas seguras con verificación biométrica.</p>
            </div>
            
            <div className="biometric-item">
              <h3 className="biometric-subtitle">Asistencia virtual</h3>
              <p className="biometric-text">Respuestas personalizadas a tus preguntas legales</p>
            </div>

            <div className="biometric-item">
              <h3 className="biometric-subtitle">Conexión con expertos</h3>
              <p className="biometric-text">Accede a abogados especializados.</p>
            </div>
          </div>
        </div>
      </section>
      
      <section className="contact-form-section" id="contact">
        <h2 className="section-title">Contáctanos</h2>
        <div className="contact-form-container">
          <form className="contact-form" action="https://formsubmit.co/moises.s@iwie.ai" method="POST">
            <div className="form-group">
              <label htmlFor="name">Nombre</label>
              <input type="text" id="name" name="name" required />
            </div>
            <div className="form-group">
              <label htmlFor="phone">Número de teléfono</label>
              <input type="tel" id="phone" name="phone" required />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input type="email" id="email" name="email" required />
            </div>
            <div className="form-group">
              <label htmlFor="message">Mensaje</label>
              <textarea id="message" name="message" required></textarea>
            </div>
            <button type="submit" className="submit-button">Enviar</button>
            
            <input type="hidden" name="_next" value="https://sinnotaria.cl/" />
            <input type="hidden" name="_captcha" value="false" />
          </form>
        </div>
      </section>

      <footer className="footer">
        <div className="footer-content">
          <a href="https://iwie.space/" target="_blank" rel="noopener noreferrer">
            <img src={logoIwie} alt="iwie.space logo" className="footer-logo" />
          </a>
          <h2 className="footer-title">Creatividad y Desarrollo Colaborativo</h2>
          <p className="footer-copyright">Copyright 2024 - Todos los derechos reservados</p>
          <div className="social-links">
            <a href="https://www.tiktok.com/@iwie.space" target="_blank" rel="noopener noreferrer" className="social-link">
              <i className="fab fa-tiktok"></i>
            </a>
            <a href="https://www.instagram.com/iwie.space" target="_blank" rel="noopener noreferrer" className="social-link">
              <i className="fab fa-instagram"></i>
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
